import moment from 'moment-timezone';

export default {
    data() {
        return {
            // apiOperationUrl: 'https://api.morefurniture.id/uni/',// 'http://172.18.130.56:1234/api/',
            // apiOperationUrl:'https://api.mockfly.dev/mocks/a9c81cac-8069-42d9-8c2a-b71949b7e15e/'
        };
    },
    methods: {
        formatDateAndTime(value){
            return (value) ? moment(String(value)).format('DD/MM/YYYY HH:mm:ss') : "";
        },
        formatNumberToCurrency(value) {
            return Number(value).toLocaleString('id-ID', {maximumFractionDigits: 0, style:"currency", currency:"IDR"})
        },
        formatNumberr(value){
            return value.toLocaleString('id-ID', { maximumFractionDigits: 0 });
        },
        formatNumberToPercent(value){
            return Number(value).toFixed(2) + "% "
        },
        formatNumber(value,berapaAngkabelakang = 0){
            return Number(value).toFixed(berapaAngkabelakang);
        },
        formatNumberTwoDigitsBehind(value){
            return Number(value).toFixed(2);
        },
        formatInputDate(date, join = '/') {
            var d = new Date(date),
                month = '' + (d.getMonth() + 1),
                day = '' + d.getDate(),
                year = d.getFullYear();
        
            if (month.length < 2) 
                month = '0' + month;
            if (day.length < 2) 
                day = '0' + day;
        
            return [month, day, year].join( join );
        },
        logout() {
            window.sessionStorage.clear();
            this.$router.push( { path: '/login' } );
            return;
        },
        getToken() {
            const token = this.getSessionStorage("Token");
            if(!token) return this.logout();
            return `Bearer ${token}`;
        },
        async fetchData_(url, params = {}, method = 'GET', isLoading = false) {
            // let loader;
            if (isLoading) {
                // loader = this.$loading.show({ "z-index": 999999 })
                this.$swal.fire({
                    title: '<span class="loader mt-3"></span>',
                    allowEscapeKey: false,
                    allowOutsideClick: false,
                    allowEnterKey: false,
                    showConfirmButton: false,
                    heightAuto: true,
                    width: 200,
                    html: '<h4 class="fw-bolder">Memuat...</h4>',
                    });
            }
            var headers = { 
                "Access-Control-Allow-Origin": "*",
                "Authorization": this.getToken(),
                'Content-Type': 'application/json'
            } 
            try {
                let payload={};
                switch(method){
                    case 'GET':
                        payload =  {
                            method,
                            headers,
                        };
                        url += "?"
                        url += new URLSearchParams(params)
                        break;
                    default : 
                        payload =  {
                            method,
                            headers,
                            body: JSON.stringify(params),
                            credentials: 'same-origin'
                        };
                    break
                }
                
                

                const response = await fetch(process.env.VUE_APP_BASE_URL + url, payload);
              
                if(isLoading) this.$swal.close(); 
                    // loader.hide();
                if ( [200,201].find(x => x == response.status) ) return response.json()
                else if ( [400].find(x => x == response.status) ) {
                    if (method == 'GET') return Promise.reject(response)
                    else return response.json();
                }
                else if ( [401].find(x => x == response.status) ) this.logout();
                else if ( [404, 408].find(x => x == response.status) ) return Promise.reject(response)
                else if ( [403].find(x => x == response.status) ) return Promise.reject("Maap anda tidak memiliki Hak Akses 🙏, Silahkan Hubungin Admin!")
                else return Promise.reject("Maap terjadi Error di sisi server 🙏");
            } catch (error) {
                if (isLoading) this.$swal.close();
            
                if (error instanceof TypeError) {
                    return Promise.reject("Gagal terhubung ke server. Silakan periksa koneksi internet Anda atau coba lagi nanti.");
                }

                return Promise.reject(error.response ? error.response : error);
            }
        },
        async fetchReport_(url, params = {}, isLoading = false) {
            // let loader;
            if (isLoading) {
                // loader = this.$loading.show({ "z-index": 999999 })
                this.$swal.fire({
                    title: '<span class="loader mt-3"></span>',
                    allowEscapeKey: false,
                    allowOutsideClick: false,
                    allowEnterKey: false,
                    showConfirmButton: false,
                    heightAuto: true,
                    width: 200,
                    html: '<h4 class="fw-bolder">Loading</h4>',
                    });
            }
            var headers = { 
                "Access-Control-Allow-Origin": "*",
                "Authorization": this.getToken(),
                'Content-Type': 'application/json'
            } 
            try {
                let payload={
                    responseType: 'blob',
                    method: 'POST',
                    headers,
                    body: JSON.stringify(params),
                    credentials: 'same-origin'
                };
                const response = await fetch(process.env.VUE_APP_BASE_URL + url, payload);
                if(isLoading) this.$swal.close(); 
                    // loader.hide();
                if ( response.ok ) return response.blob()
                else return Promise.reject("Gagal download!");
            } catch (error) {
                // console.log(error)
                if(isLoading) this.$swal.close(); // loader.hide();
                return Promise.reject(error.response ? error.response: error)
            }
        },
        async uploadFile_(url, file, isLoading = false, params = {}) {
            // Show loading spinner if `isLoading` is true
            if (isLoading) {
                this.$swal.fire({
                    title: '<span class="loader mt-3"></span>',
                    allowEscapeKey: false,
                    allowOutsideClick: false,
                    allowEnterKey: false,
                    showConfirmButton: false,
                    heightAuto: true,
                    width: 200,
                    html: '<h4 class="fw-bolder">Loading</h4>',
                });
            }
        
            // Prepare headers, but omit 'Content-Type' because the browser will handle it when using FormData
            var headers = { 
                "Access-Control-Allow-Origin": "*",
                "Authorization": this.getToken(),
                // No need to set Content-Type here
            };
        
            // Create a FormData object to include the file and any additional parameters
            let formData = new FormData();
            formData.append('file', file);  // append the file
        
            try {
                // Prepare the fetch payload
                let payload = {
                    method: 'POST',
                    headers,
                    body: formData,  // Use FormData as the body
                    credentials: 'same-origin'
                };
                
                url += "?"
                url += new URLSearchParams(params)

                // Send the request
                const response = await fetch(process.env.VUE_APP_BASE_URL + url, payload);
        
                // Close the loading modal if it's open
                if (isLoading) this.$swal.close();
                console.log(response);
                // Handle the response
                if (response.ok) {
                    return await response.json();  // Assuming server returns JSON
                
                } else {
                    if ([400].find(x => x == response.status) ) {
                        return response.json();
                    }else{
                        return Promise.reject("Upload failed!");
                    }
                }
            } catch (error) {
                if (isLoading) this.$swal.close();
                if (error instanceof TypeError) {
                    return Promise.reject("Gagal terhubung ke server. Silakan periksa koneksi internet Anda atau coba lagi nanti.");
                }
                return Promise.reject(error.response ? error.response : error);
            }
        },        
        downloadFile(blob, name = "Report.xlsx") {
            const url = window.URL.createObjectURL(blob);

            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', name); // Specify the file name

            document.body.appendChild(link);
            link.click();
        },
        async getDataById_(id, url) {
            try {
                const response = await this.fetchData_(url + '/' + id, {}, 'GET', true);
                var data = response.Data
                this.$store.state[this.$router.currentRoute.value.path] = data;
            } catch (error) {
                this.$swal({
                    icon: 'error',
                    title: 'Oops...',
                    text: error.statusText,
                })  
                
                return Promise.reject(error)
            }
        },
        getSessionStorage(key){
            const data = window.sessionStorage.getItem('expos');
            if(!data) return this.logout();
            const parsedData = JSON.parse(data);
            return parsedData[key];
        },
        async removeViewMode(url, id) {
            this.$swal({
                title: "Apakah anda yakin menghapus data ini?",
                icon:'warning',
                showCancelButton: true,
                confirmButtonText: "Hapus",
                cancelButtonText: 'Batal'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    try {
                        const response = await this.fetchData_(url + '/' + id, {}, 'DELETE', true);
                        this.$swal({
                            icon: 'success',
                            title: 'Data telah di hapus',
                            text: response.statusText,
                        });
                        this.$refs.dataTable.load()
                    } catch (error) { 
                        this.$swal({
                            icon: 'error',
                            title: 'Oops...',
                            text: error.statusText,
                        })
                    }
                }
            })
            
        }
    }
}