<template>
    <label class="mb-0 text-primary fw-bold">{{ title_ }} <span v-if="required_" class="text-danger">*</span></label>
    <div :class="'input-group ' + classGroup_ + ' ' + ' ' + (error_ ? 'is-invalid': '')">
        <date-picker 
            :modelValue="modelValue" 
            :class="class_" 
            :previewFormat="previewFormat_" 
            :format="format"
            :placeholder="placeholder_"
            @update:modelValue="$emit('update:modelValue', $event)"
            :disabled="disabled_"
            :enable-time-picker="false" 
            :min-date="minDate_"
            :max-date="maxDate_"
            locale="id"
            :clearable="clearable_"
            auto-apply
            :month-change-on-scroll="false"
            cancelText="Batal" selectText="Pilih">
        </date-picker>
    </div>
    <small v-if="error_" class="text-danger fst-italic error-text lh-sm">
            <span v-for="(error,index) in error_" :key="index" class="d-block">
                {{ error }}
            </span>
    </small>
</template>

<script>

// import moment from 'moment-timezone';

export default {
    props: {
        previewFormat_:             { type: String, default: 'dd/MM/yyyy' },
        format_:                    { type: String, default: 'dd/MM/yyyy' },
        classGroup_:                { type: String, default: 'input-group-outline' },   //input-group-dynamic
        class_:                     { type: String, default: '' },
        title_:                     { type: String },
        error_:                     { type: Array },
        placeholder_:               { type: String },
        modelValue:                 { type: [String, Date] },
        disabled_:                  { type: Boolean, default: false },
        required_:                  { type: Boolean, default: false },
        minDate_:                   { type: Date, default: null },
        maxDate_:                   { type: Date, default: null },
        clearable_:                 { type: Boolean, default: true },
    },
    data: () => {
        return {
            date: ''
        }
    },
    emits: ['update:modelValue'],
    setup() {
        const format = (date) => {
            const day = String(date.getDate()).padStart(2, '0');
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const year = date.getFullYear();
            // const day = date.getDate();
            // const month = date.getMonth() + 1;
            // const year = date.getFullYear();
            return `${day}/${month}/${year}`;

            // return moment(date).format('DD/MM/yyyy');
        }
        return {
            format
        }
    },
}
</script>

<style>
    .is-invalid{
        border: solid #dc3545 1px !important;
        border-radius: 0.375rem !important;
    }
    .dp__input{
        font-weight: 400 !important;
        line-height: 1.62 !important; 
    }
    .dp__disabled{
        background-color: #dcdde2 !important;
    }
</style>